import { User } from "@my/api"
import { setDatadogUser } from "./datadog"
import { mixpanel } from "./mixpanel"

export * from "./datadog"
export * from "./mixpanel"

export function setMonitoringUser(user: User) {
  setDatadogUser(user)
  mixpanel.identify(user.auth0Id)
  mixpanel.setSuperProperties({
    first_seen: user.createdAt,
  })
  mixpanel.setProfile({
    first_seen: user.createdAt,
    user_utm_source: user.utm?.utmSource,
    user_utm_medium: user.utm?.utmMedium,
    user_utm_campaign: user.utm?.utmCampaign,
    user_utm_content: user.utm?.utmContent,
    user_utm_term: user.utm?.utmTerm,
    $email: user.email,
  })
}

export const trackButtonClicked = (
  buttonName: string,
  pageName: string,
  additionalProperties: { [key: string]: any } = {},
) => {
  mixpanel.track("Button Clicked", {
    button_name: buttonName,
    page_name: pageName,
    ...additionalProperties,
  })
}

/**
 * Tracks when the app is installed by a user.
 *
 * @param additionalProperties - Optional object containing additional properties to track with the install event
 *
 * @example
 * ```ts
 * trackAppInstall({ utm_source: 'google', utm_medium: 'cpc' })
 * ```
 */
export const trackAppInstall = (additionalProperties: Record<string, any> = {}) => {
  mixpanel.track("App Install", additionalProperties)
}
