import { useAuth } from "@my/api"
import { useMemo } from "react"
import { resetAllStores } from "../global-state"

export const useLogout = () => {
  const { logout } = useAuth()

  return useMemo(
    () => ({
      handleLogout: () => {
        resetAllStores()
        logout()
      },
    }),
    [logout],
  )
}
