import closedLoopColors from "./tokens"
import defaultColors from "../default/tokens"

export const light = {
  ...closedLoopColors?.light,

  // Tamagui built-in theme values
  background: defaultColors.light.slate1,
  backgroundPrimary: defaultColors.light.slate1,
  backgroundHover: defaultColors.light.slate5,
  backgroundPress: defaultColors.light.slate4,
  backgroundFocus: defaultColors.light.slate3,

  borderColor: defaultColors.light.slate7,
  borderColorHover: defaultColors.light.slate4,
  borderColorPress: defaultColors.light.slate4,
  borderColorFocus: defaultColors.light.slate5,

  color: defaultColors.light.slate12,
  colorInverse: defaultColors.light.slate1,
  colorHover: defaultColors.light.slate12,
  colorPress: defaultColors.light.slate12,
  colorFocus: defaultColors.light.slate12,

  shadowColor: "rgba(0, 0, 0, 0.15)",
  shadowColorHover: defaultColors.light.slate10,
  shadowColorPress: defaultColors.light.slate11,
  shadowColorFocus: defaultColors.light.slate10,

  // ClosedLoop additional theme values
  backgroundSecondary: defaultColors.light.slate3,
  backgroundDetail: defaultColors.light.slate1,
  backgroundSelected: defaultColors.light.blue4,
  backgroundInfo: defaultColors.light.blue9,
  backgroundSuccess: defaultColors.light.green9,
  backgroundWarning: defaultColors.light.orange8,
  backgroundWarningLight: defaultColors.light.orange11,
  backgroundError: defaultColors.light.red9,
  backgroundInverse: defaultColors.light.slate12,
  backgroundOverlay: "rgba(0, 0, 0, 0.48)",
  backgroundInput: defaultColors.light.slate1,
  backgroundMessage: defaultColors.light.slate1,

  borderContainer: defaultColors.light.slate6,
  borderError: defaultColors.light.red9,
  borderWarning: defaultColors.light.orange10,
  borderFocus: defaultColors.light.slate8,
  borderSelected: defaultColors.light.blue9,

  buttonPrimary: "#0F6CBD",
  buttonPrimaryHover: "#2C7EC5",
  buttonPrimaryPress: "#5C9BD2",
  buttonPrimaryFocus: "#498FCD",
  buttonPrimaryDisabled: "#C1C8CD",
  buttonPrimaryText: "#FBFCFD",
  buttonSecondary: "#EBF3FC",
  buttonSecondaryHover: "#D9E8F7",
  buttonSecondaryPress: "#96C7F2",
  buttonSecondaryFocus: "#96C7F2",
  buttonSecondaryDisabled: "#C1C8CD",
  buttonSecondaryText: "#11181C",
  buttonTertiary: "transparent",
  buttonTertiaryHover: "#E2EEF9",
  buttonTertiaryPress: "#E2EEF9",
  buttonTertiaryFocus: "#D1E3F4",
  buttonTertiaryDisabled: "transparent",
  buttonTertiaryText: "#11181C",

  icon: defaultColors.light.slate12,
  iconDark: defaultColors.light.slate1,
  iconLight: defaultColors.light.slate12,
  iconSecondary: defaultColors.light.slate10,
  iconError: defaultColors.light.red11,
  iconWarning: defaultColors.light.orange11,
  iconSuccess: defaultColors.light.green11,
  iconInfo: defaultColors.light.blue11,
  iconSelected: defaultColors.light.blue11,
  iconAction: defaultColors.light.blue11,
  iconDangerous: defaultColors.light.red11,

  text: defaultColors.light.slate12,
  textInverse: defaultColors.light.slate1,
  textHover: defaultColors.light.slate12,
  textPress: defaultColors.light.slate12,
  textFocus: defaultColors.light.slate12,
  textSecondary: defaultColors.light.slate10,
  textDisabled: defaultColors.light.slate8,
  textDark: defaultColors.light.slate1,
  textLight: defaultColors.light.slate12,
  textLink: defaultColors.light.blue11,
  textAction: defaultColors.light.blue11,
  textSelected: defaultColors.light.slate12,
  textDangerous: defaultColors.light.red11,
  textWarning: defaultColors.light.orange10,

  chartAxis: defaultColors.light.slate7,
  chartGrid: defaultColors.light.slate8,
  chartGridDark: defaultColors.light.slate9,
  chartLabel: defaultColors.light.slate10,
  chartTicks: defaultColors.light.slate9,
  chartTicksLabels: defaultColors.light.slate10,
  chartDataBorder: defaultColors.light.slate3,
  chartDataPrimary: defaultColors.light.blue8,
  chartDataWarning: defaultColors.light.orange10,
}

export type BaseTheme = typeof light

export const dark: BaseTheme = {
  ...closedLoopColors?.dark,

  // Tamagui built-in theme values
  background: defaultColors.dark.slate1,
  backgroundPrimary: defaultColors.dark.slate1,
  backgroundHover: defaultColors.dark.slate4,
  backgroundPress: defaultColors.dark.slate4,
  backgroundFocus: defaultColors.dark.slate3,

  borderColor: defaultColors.dark.slate7,
  borderColorHover: defaultColors.dark.slate4,
  borderColorPress: defaultColors.dark.slate4,
  borderColorFocus: defaultColors.dark.slate5,

  color: defaultColors.dark.slate12,
  colorInverse: defaultColors.dark.slate1,
  colorHover: defaultColors.dark.slate11,
  colorPress: defaultColors.dark.slate11,
  colorFocus: defaultColors.dark.slate11,

  shadowColor: defaultColors.dark.slate9,
  shadowColorHover: defaultColors.dark.slate10,
  shadowColorPress: defaultColors.dark.slate11,
  shadowColorFocus: defaultColors.dark.slate10,

  // ClosedLoop additional theme values
  backgroundSecondary: defaultColors.dark.slate3,
  backgroundDetail: defaultColors.dark.slate1,
  backgroundSelected: defaultColors.dark.blue4,
  backgroundInfo: defaultColors.dark.blue9,
  backgroundSuccess: defaultColors.dark.green9,
  backgroundWarning: defaultColors.dark.orange9,
  backgroundWarningLight: defaultColors.dark.orange11,
  backgroundError: defaultColors.dark.red9,
  backgroundInverse: defaultColors.dark.slate12,
  backgroundOverlay: "rgba(0, 0, 0, 0.48)",
  backgroundInput: defaultColors.light.slate1,
  backgroundMessage: defaultColors.dark.slate1,

  borderContainer: defaultColors.dark.slate6,
  borderError: defaultColors.dark.red9,
  borderWarning: defaultColors.dark.orange10,
  borderFocus: defaultColors.dark.slate8,
  borderSelected: defaultColors.dark.blue9,

  buttonPrimary: "#8EBEEE",
  buttonPrimaryHover: "#7AB1E6",
  buttonPrimaryPress: "#4C93D5",
  buttonPrimaryFocus: "#358EE3",
  buttonPrimaryDisabled: "#C1C8CD",
  buttonPrimaryText: "#11181C",
  buttonSecondary: "#082338",
  buttonSecondaryHover: "#1C3548",
  buttonSecondaryPress: "#576978",
  buttonSecondaryFocus: "#435868",
  buttonSecondaryDisabled: "#C1C8CD",
  buttonSecondaryText: "#ECEDEE",
  buttonTertiary: "transparent",
  buttonTertiaryHover: "#1C3548",
  buttonTertiaryPress: "#1C3548",
  buttonTertiaryFocus: "#435868",
  buttonTertiaryDisabled: "transparent",
  buttonTertiaryText: "#ECEDEE",

  icon: defaultColors.dark.slate12,
  iconDark: defaultColors.dark.slate1,
  iconLight: defaultColors.dark.slate12,
  iconSecondary: defaultColors.dark.slate10,
  iconError: defaultColors.dark.red11,
  iconWarning: defaultColors.dark.orange11,
  iconSuccess: defaultColors.dark.green11,
  iconInfo: defaultColors.dark.blue11,
  iconSelected: defaultColors.dark.blue11,
  iconAction: defaultColors.dark.blue11,
  iconDangerous: defaultColors.dark.red11,

  text: defaultColors.dark.slate12,
  textInverse: defaultColors.dark.slate1,
  textHover: defaultColors.dark.slate11,
  textPress: defaultColors.dark.slate11,
  textFocus: defaultColors.dark.slate11,
  textSecondary: defaultColors.dark.slate10,
  textDisabled: defaultColors.dark.slate8,
  textDark: defaultColors.dark.slate1,
  textLight: defaultColors.dark.slate12,
  textLink: defaultColors.dark.blue10,
  textAction: defaultColors.dark.blue11,
  textSelected: defaultColors.dark.slate12,
  textDangerous: defaultColors.dark.red11,
  textWarning: defaultColors.dark.orange10,

  chartAxis: defaultColors.dark.slate7,
  chartGrid: defaultColors.dark.slate9,
  chartGridDark: defaultColors.dark.slate11,
  chartLabel: defaultColors.dark.slate10,
  chartTicks: defaultColors.dark.slate9,
  chartTicksLabels: defaultColors.dark.slate10,
  chartDataBorder: defaultColors.dark.slate3,
  chartDataPrimary: defaultColors.dark.blue10,
  chartDataWarning: defaultColors.dark.orange10,
}

export default {
  light,
  dark,
}
