export default {
  light: {
    background: "#ffffff",
    backgroundPrimary: "#eaf1fb",
    backgroundSecondary: "#f6f8fc",
    backgroundDetail: "#f6f8fc",
    backgroundInput: "#f6f8fc",
    backgroundMessage: "#f6f8fc",
    backgroundSelected: "#d3e2fd",
    backgroundWarningLight: "#f4e1d5",

    iconWarning: "#ee5e00",
  },
  dark: {
    background: "#2c2c2c",
    backgroundPrimary: "#373737",
    backgroundSecondary: "#212121",
    backgroundDetail: "#212121",
    backgroundInput: "#474747",
    backgroundMessage: "#111111",
    backgroundSelected: "#595959",
  },
}
