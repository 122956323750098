import { create } from "./create"

export const useAppStore = create<{
  threadListOpen: boolean
  setThreadListOpen: (open: boolean) => void

  bottomNavHeight: number
  setBottomNavHeight: (height: number) => void

  entityDrawerOpen: boolean
  setEntityDrawerOpen: (open: boolean) => void

  selectedEntityId: string | null
  setSelectedEntityId: (id: string | null) => void
}>((set) => {
  const setEntityDrawerOpen = (open: boolean) => {
    set({
      entityDrawerOpen: open,
    })
  }

  return {
    threadListOpen: true,
    setThreadListOpen: (open: boolean) =>
      set({
        threadListOpen: open,
      }),

    bottomNavHeight: 0,
    setBottomNavHeight: (height: number) =>
      set({
        bottomNavHeight: height,
      }),

    entityDrawerOpen: false,
    setEntityDrawerOpen,

    selectedEntityId: null,
    setSelectedEntityId: (id: string | null) => {
      setEntityDrawerOpen(id !== null)

      set({
        selectedEntityId: id,
      })
    },
  }
})
